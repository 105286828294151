<template>
  <section v-title data-title="我的">
        <div>
            <span class="span-title">关于我们</span>
            <span class="span-content">邱云网络是一个在线生活服务平台，集聚了房屋租售、求职招聘、二手买卖、便民服务、生活服务等功能的综合工具，为群众提供高效率的工作和生活相关活动平台。</span>
            <span class="span-content">邱云为各个服务平台（APP，小程序）的主要载体，目前以“邱生活”为主。</span>

            <span class="span-title">初衷</span>
            <span class="span-content">2020年12月16日，邱云网络成立，解决天津市静海区在线平台资源短缺的问题，为广大群众提供聚合、统一、规范化的平台而努力，不忘初心始终如一。</span>

            <span class="span-title">用户</span>
            <span class="span-content">面向所有群体</span>

        </div>
    </section>
</template>

<script>
export default {
    name:"about"
}
</script>

<style >
    *{
        font-family: 'SourceHanSansCN';
    }
    section{
            display: flex;
            justify-content: center;
        }

        section>div{
            padding: 50px 0px;
            min-width: 1280px;
            max-width: 1920px;
            display: flex;
            flex-direction: column;
            line-height: 30px;
            font-size: 18px;
            color: #333;
        }

        .span-title::before{
            content: "";
            display: inline-block;
            height: 14px;
            width: 5px;
            border-radius: 3px;
            background-color: rgb(31,169,219);
            margin-right: 10px;
        }

        .span-title{
            font-family: 'WenQuanYi';
            color:  rgb(31,169,219);
            margin-top: 30px;
        }
</style>